<template>
  <div class="hover-dropdown-fade w-auto px-0 ml-8 position-relative hp-cursor-pointer">
    <div class="hp-cursor-pointer border hp-border-color-dark-80" style="border-radius: 15px">
      <div class="rounded-lg overflow-hidden m-4 d-flex">
        <i class="hp-text-color-dark-0 ri-2x ri-user-3-fill mb-0 rounded-0 "></i>
      </div>
    </div>

    <div class="hp-header-profile-menu dropdown-fade position-absolute pt-18" style="top: 100%; width: 260px">
      <div class="rounded hp-bg-black-0 hp-bg-dark-100 py-24 px-18">
        <span class="d-block h5 font-weight-medium hp-text-color-black-100 hp-text-color-dark-0 mb-16">
          Menu
        </span>
        <div class="divider mt-18 mb-16"></div>

        <b-row>
          <b-col cols="12">
            <b-link  to="/" v-on:click="logout()"
              class="d-flex align-items-center font-weight-medium hp-p1-body my-4 py-8 px-10 hp-transition hp-hover-bg-primary-4 hp-hover-bg-dark-primary hp-hover-bg-dark-80 rounded"
              style="margin-left: -10px; margin-right: -10px" >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                class="mr-8">
                <path
                  d="M21.08 8.58v6.84c0 1.12-.6 2.16-1.57 2.73l-5.94 3.43c-.97.56-2.17.56-3.15 0l-5.94-3.43a3.15 3.15 0 0 1-1.57-2.73V8.58c0-1.12.6-2.16 1.57-2.73l5.94-3.43c.97-.56 2.17-.56 3.15 0l5.94 3.43c.97.57 1.57 1.6 1.57 2.73Z"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M12 11a2.33 2.33 0 1 0 0-4.66A2.33 2.33 0 0 0 12 11ZM16 16.66c0-1.8-1.79-3.26-4-3.26s-4 1.46-4 3.26"
                  stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>

              <span>Logout</span>
            </b-link>
          </b-col>

        
        </b-row>

        <div class="divider mt-12 mb-18"></div>


      
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BAvatar, BLink } from "bootstrap-vue";
import authService from "../../../services/auth.service";

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BLink,
  },
  data() {
    return {

    }
  },
  methods : {
    logout(){
      authService.logout();
    }
  }
};
</script>
