export default {
    header : "ตั้งค่าผู้ใช้งาน",
    children:[
      {
        id: "users",
        iconSvg: '<i class="hp-text-color-dark-1 iconly-Light-TwoUsers"></i>',
        title: "ผู้ใช้งาน",
        navLink: "/pages/settings/users",
      },
    ]
}