import dashboards from "./dashboards"
import settings from "./settings"
import generalSettings from "./general-settings"
import dashboardsUser from "./dashboards.user";


let menus = [];
if(localStorage.getItem('access-role') == 'administrator')
  {
    menus = [dashboards,generalSettings,settings];
  }else{
    menus = [dashboardsUser];
  }
   
  export default menus;