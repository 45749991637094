export default {
    header: "เมนูหลัก",
    children: [
        {
            id: "dashboards",
            title: "แดชบอร์ด",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Light-Graph"></i>',
            navLink: "/pages/dashboards",
        }, {
            id: "article-form",
            title: "ฟอร์มบทความ",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Light-Document"></i>',
            navLink: "/pages/forms/article-form",
        },
        {
            id: "initial-assessment-form",
            title: "ฟอร์มกลั่นกรองต้นฉบับ",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Light-Document"></i>',
            navLink: "/pages/forms/initial-assessment-form",
        },
        {
            id: "receipts",
            title: "ใบเสร็จรับเงิน",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Broken-Wallet"></i>',
            navLink: "/pages/payments/receipts",
        }
    ]
}