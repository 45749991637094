export default {
    header: "ตั้งค่าทั่วไป",
    children: [
        {
            id: "articles",
            title: "บทความ",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Broken-Paper"></i>',
            children: [
                {
                    id: "categories",
                    title: "หมวดหมู่",
                    navLink: "/pages/articles/categories",
                },
                {
                    id: "types",
                    title: "ประเภทบทความ",
                    navLink: "/pages/articles/types",
                },
                {
                    id: "publishes",
                    title: "ประเภทเผยแพร่",
                    navLink: "/pages/articles/publishes",
                },
                {
                    id: "status",
                    title: "สถานะบทความ",
                    navLink: "/pages/articles/status",
                },
            ],
        },
        {
            id: "journal",
            title: "วารสาร",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Light-Message"></i>',
            title: "วารสาร",
            navLink: "/pages/journals/journal",
        }, {
            id: "personnel",
            title: "บุคลากร",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Curved-People"></i>',
            children: [
                {
                    id: "experts",
                    title: "ผู้ทรงคุณวุฒิ",
                    navLink: "/pages/personnel/experts",
                },
                {
                    id: "inspectors",
                    title: "ผู้ทรงประจำกองบรรณาธิการ",
                    navLink: "/pages/personnel/inspectors",
                },
                {
                    id: "authors",
                    title: "ผู้เขียน",
                    navLink: "/pages/personnel/authors",
                },
            ],
        },  {
            id: "comments",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Light-Chat"></i>',
            title: "ความคิดเห็น",
            navLink: "/pages/commentation/comments",
        },
        {
            id: "institutions",
            iconSvg: '<i class="hp-text-color-dark-0 iconly-Curved-Work"></i>',
            title: "หน่วยงาน",
            navLink: "/pages/payments/institution",
          },
    ]
}